<template>
  <WcModal @backClick="backClicked">
    <transition name="slideup">
      <div class="bottom-modal" v-if="loaded">
        <div class="bottom-modal-content">
          <div class="modal-content-header" v-if="!hideHeader">
            <div class="header-close-btn">
              <a href="#" @click.prevent="closeClicked" v-if="!hideClose">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                  <g clip-path="url(#clip0_3689_29746)">
                    <line x1="11.0138" y1="11.127" x2="24.8731" y2="24.9862" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
                    <line x1="11.127" y1="24.9862" x2="24.9862" y2="11.1269" stroke="#4D4D4D" stroke-width="2" stroke-linecap="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_3689_29746">
                      <rect width="36" height="36" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>

            <div class="header-title">
              <p v-if="!hideTitle">{{ title }}</p>
            </div>

            <div class="header-confirm">
              <a v-if="!hideConfirm" href="#" @click.prevent="confirmClicked">確認</a>
            </div>
          </div>
          <slot></slot>
        </div>
      </div>
    </transition>
  </WcModal>
</template>

<script>
import WcModal from '@/components/commons/WcModal.vue';

export default {
  name: 'WcBottomModal',
  data() {
    return {
      loaded: false,
    }
  },
  components: {
    WcModal,
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 0);
  },
  watch: {
  },
  computed: {
    
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    backClicked() {
      this.$emit('backClick');
    },
    closeClicked() {
      this.$emit('close');
    },
    confirmClicked() {
      this.$emit('confirm');
    },
  }

}
</script>

<style lang="scss" scoped>

  .bottom-modal {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .bottom-modal-content {
    position: relative;
    padding: 1rem .75rem 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: #fff;

    .modal-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-close-btn {
        flex: 0 0 20%;
        a {

        }
      }
      .header-title {
        flex: 0 0 auto;
        p {
          margin: 0;
          color: var(--text-color-main);
          font-weight: 1000;
          font-size: 1.2rem;
        }
      }

      .header-confirm {
        flex: 0 0 20%;
        text-align: right;
      }
    }
  }
</style>
