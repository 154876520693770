<template>
  <div class="cloth-card-block">
    <div class="rounded-block">
      <div class="rounded-inner">
        <img class="bg-cloth-img" :src="cloth.imgs[0]" />

        <div class="lock-mask" v-if="!cloth.isPublic && showTitle">
          <img :src="require('@/assets/icons/lock.png')" />
        </div>

        <div class="front-label" v-if="isViewMode && showTitle">
          {{ title }}
        </div>

        <div class="front-select" :class="{'selected': selected}" v-if="isSelectMode">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <g clip-path="url(#clip0_3742_29821)">
              <circle cx="8.66602" cy="8" r="8" fill="#8D8D8D"/>
              <path d="M5.66602 8.22222L7.61046 10.7222L12.3327 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3742_29821">
                <rect width="16" height="16" fill="white" transform="translate(0.666016)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'ClothCardBlock',
  data() {
    return {
      
    }
  },
  props: {
    cloth: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTitleType: {
      type: String,
      required: false,
      default: 'category',
    },
    mode: {
      type: String,
      required: false,
      default: 'view',
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  created() {
  },
  computed: {
    ...mapState(['clothColors', 'clothCategories']),
    category1() {
      const res = this.clothCategories.filter((item) => {
        return this.cloth.category1 === item.serial;
      });
      return res[0];
    },
    category1Name() {
      return this.category1.name;
    },
    category2Name() {
      const res = this.category1.sub.filter((item) => {
        return this.cloth.category2 === item.serial;
      });
      if (res.length > 0) {
        return res[0].name;
      }
      return '';
    },
    title() {
      const titleType = this.showTitleType;
      switch(titleType) {
        case 'category':
        return this.category2Name;
        case 'price':
        return this.cloth.price<0? '未設定' : `NTD ${this.cloth.price.toLocaleString('en')}`;
        case 'getTime':
          if (this.cloth.getYear === -1) {
            return '未設定';
          } else if (this.cloth.getMonth === -1) {
            return `${this.cloth.getYear}`;
          } else {
            return `${this.cloth.getYear}.${this.cloth.getMonth.toString().padStart(2, '0')}`;
          }
        case 'createTime':
          return moment(this.cloth.create_at).format('YYYY.MM');
        case 'wearCount':
          return this.cloth.wearCount !== undefined?this.cloth.wearCount.toString().padStart(3, '0'): 0;
      }
      return this.category2Name;
    },
    isViewMode() {
      return this.mode === 'view';
    },
    isSelectMode() {
      return this.mode === 'select';
    },
  },
  mounted() {
  },
  watch: {
    
  },
  methods: {
    
  }

}
</script>

<style lang="scss" scoped>
  .cloth-card-block {
    position: relative;
  }

  .rounded-block {
    position: relative;
    padding-top: 100%;
    border-radius: .5rem;
    background-color: #EEE;
    overflow: hidden;
  }

  .rounded-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* padding: .5rem; */
  }

  .bg-cloth-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .front-label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem .5rem .2rem;
    color: var(--color-main);
    background: linear-gradient(0deg, #CACACA 15%, rgba(217, 217, 217, 0) 70%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: normal;
  }

  .lock-mask {
    position: absolute;
    /* width: 30%;
    height: 30%;
    top: -15%;
    left: -15%;
    transform: rotateZ(45deg);
    background-color: var(--main-yellow); */
    top: 5px;
    right: 5px;
  }

  .lock-mask>img {
    width: 16px;
    display: block;
  }

  .front-select {
    position: absolute;
    right: .3rem;
    bottom: .3rem;
    &.selected circle {
      fill: var(--icon-color-highlight);
    }
  }
</style>
